import { useMemo, useCallback, useEffect, useRef, useState } from 'react'
import badgerImageSrc from 'assets/badger_icon_circle-01.png'
import { saveAs } from 'file-saver'
import { v4 as uuid } from 'uuid'
import KeepReadingButton from 'components/KeepReadingButton'
import successAudioSrc from 'assets/Correct.wav'
import failAudioSrc from 'assets/Wrong.wav'
import AudioPlayerButton from 'components/AudioPlayerButton'
import checkAudioButton from 'assets/CheckAudioButton@2x.png'
import { connect } from 'react-redux'
import { readingModes } from 'config'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import api from 'api'
import * as bookActions from 'actions/book'
import styles from './styles.module.scss'
import wallBackgroundImageSrc from 'assets/wall-tile.png'
import ComprehensionCheckTypeC from './ComprehensionCheckTypeC'
import ComprehensionCheckTypeB from './ComprehensionCheckTypeB'
import DecodingCheck from './DecodingCheck'
import NextPageButton from './NextPageButton'
import PrevPageButton from './PrevPageButton'
import cn from 'classnames'
import AudioPlayer from 'react-audio-player'
import LoadingOverlay from 'components/LoadingOverlay'
import HomeButton from 'components/HomeButton'
import { Carousel, CarouselItem } from 'reactstrap'
import Toolbar from './Toolbar'
import Slider from './Slider'
import Stage from './Stage'

const STAGE_STATE = {
  PRE_READING: 0,
  READING: 1,
}

const PAGE_STEP = {
  INITIAL: 0,
  READ_ALOUD: 1,
  FINISHED: 2,
}

const Reader = ({
  bookInfo,
  readability,
  category,
  toolbox,
  bookId,
  mode,
  readers = [],
  teacher,
  dispatch,
  currentPageIndex,
  currentWordIndex,
  events,
  pageDone,
}) => {
  const history = useHistory()
  const auth0 = useAuth0()
  const sessionStart = useMemo(() => new Date(), [])
  const successAudioRef = useRef(null)
  const failAudioRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [currentReader, setCurrentReader] = useState({
    index: 0,
    avatarImgSrc: null,
  })
  const [done, setDone] = useState(false)
  const pageItems = useMemo(() => bookInfo.getPageItems(), [bookInfo])
  const [currentState, setCurrentState] = useState(STAGE_STATE.PRE_READING)
  const [readAloudPlaying, setReadAloudPlaying] = useState(false)
  const [pageStep, setPageStep] = useState(PAGE_STEP.INITIAL)
  const isReading = () => currentState === STAGE_STATE.READING
  const isRunningVideo = () => currentState === STAGE_STATE.PRE_READING
  // http://react-responsive-carousel.js.org/storybook/?path=/story/02-advanced--presentation-mode

  const createEvent = useCallback(
    ({ readerIndex, ...props }) => {
      const eventData = {
        childID: readers[readerIndex].ID,
        whichChild: `child${readerIndex + 1}`,
        timestamp: new Date().toISOString(),
        ...props,
      }
      return eventData
    },
    [readers]
  )

  const playAudioRef = ({ current }) => {
    const audio = current?.audioEl?.current
    if (audio && audio.paused) {
      audio.play()
    }
  }

  const playSuccessAudio = () => {
    playAudioRef(successAudioRef)
  }

  const playFailAudio = () => {
    playAudioRef(failAudioRef)
  }

  const next = () => {
    if (pageStep === PAGE_STEP.INITIAL) {
      setPageStep(PAGE_STEP.READ_ALOUD)
    } else if (pageStep === PAGE_STEP.FINISHED) {
      setPageStep(PAGE_STEP.INITIAL)
      dispatch(bookActions.nextPage())
    }
  }

  const previous = () => {
    setPageStep(PAGE_STEP.INITIAL)
    if (currentPageIndex > 0) {
      dispatch(bookActions.prevPage())
    }
  }
  
  const last = () => {
    if (pageStep === PAGE_STEP.INITIAL) {
      setPageStep(PAGE_STEP.READ_ALOUD)
    } else if (pageStep === PAGE_STEP.FINISHED) {
      setPageStep(PAGE_STEP.INITIAL)
    }
    dispatch(bookActions.lastPage(bookInfo.getPageLength()))
  }

  useEffect(() => {
    return () => dispatch(bookActions.clear())
  }, [dispatch])

  useEffect(() => {
    const currentReaderIndex = currentPageIndex % readers.length
    setReadAloudPlaying(false)
    setCurrentReader({
      index: currentReaderIndex,
      avatarImgSrc: readers[currentReaderIndex]?.picture,
    })
  }, [currentPageIndex, readers])

  if (!mode || !readers[0]) {
    history.replace('/')
    return null
  }

  const addEventOnce = (event) => {
    if (!getEventByType(event.eventType)) {
      dispatch(bookActions.addEvent(event))
    }
  }

  const handleChallengeResponse = (responseCorrect) => {
    if (!responseCorrect) {
      return playFailAudio()
    }
    playSuccessAudio()
    setTimeout(()=>dispatch(bookActions.nextPage()), 2000)
    
  }

  const uploadAudioFile = async (mediaBlobUrl) => {
    const token = await auth0.getAccessTokenSilently({
      authorizationParams:{
        audience:process.env.REACT_APP_AUTH_AUDIENCE,
        scope: process.env.REACT_APP_AUTH_SCOPE
      }
    })
    const blob = await fetch(mediaBlobUrl).then((r) => r.blob())
    const filename = `${uuid()}.wav`
    const file = new File([blob], filename, { type: 'audio/wav' })
    //    saveAs(file)
    const data = new FormData()
    data.append('content_type', 'audio/wav')
    data.append('file', file)
    await api
      .post('/api/session/upload', data, {
        headers: {
          authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
        },
      })
      .catch(console.error)
    return filename
  }

  const renderChallenge = ({ challengeKey, challengeType, ...props }) => {
    switch (challengeType) {
      case 'ComprehensionCheckTypeC':
        return (
          <ComprehensionCheckTypeC
            {...props}
            key={challengeKey}
            handleSubmit={(responseCorrect) => {
              addEventOnce(
                createEvent({
                  readerIndex: currentReader.index,
                  eventType: challengeKey,
                  response: responseCorrect ? 'correct' : 'incorrect',
                  comprehensionType: 'C',
                })
              )
              handleChallengeResponse(responseCorrect)
            }}
          />
        )

      case 'ComprehensionCheckTypeB':
        const { choices, audio } = props
        return (
          <ComprehensionCheckTypeB
            key={challengeKey}
            audio={bookInfo.requestMediaFile(audio)}
            image1={bookInfo.requestMediaFile(choices[0].image)}
            image2={bookInfo.requestMediaFile(choices[1].image)}
            handleSubmit={(i) => {
              addEventOnce(
                createEvent({
                  readerIndex: currentReader.index,
                  eventType: challengeKey,
                  response: choices[i].isCorrect ? 'correct' : 'incorrect',
                  comprehensionType: 'B',
                })
              )
              handleChallengeResponse(choices[i].isCorrect)
            }}
          />
        )

      case 'DecodingCheck':
        const { words } = props
        return (
          <DecodingCheck
            pageStep={pageStep}
            PAGE_STEP={PAGE_STEP}
            words={words}
            key={challengeKey}
            readerIndex={currentReader.index}
            handleSubmit={async (mediaBlobUrl) => {
              const childID = readers[currentReader.index].ID
              const filename = await uploadAudioFile(mediaBlobUrl)
              const decodingCheckEvent = createEvent({
                readerIndex: currentReader.index,
                eventType: challengeKey,
                filename,
              })
              dispatch(bookActions.addEvent(decodingCheckEvent))
              dispatch(bookActions.nextPage())
            }}
          />
        )

      default:
        throw new Error()
    }
  }

  const isLastWord = (wordIndex = currentWordIndex) => {
    return wordIndex === pageItems[currentPageIndex]?.words?.length - 1
  }

  const handleWordChange = (wordIndex) => {
    const wordEvent = createEvent({
      readerIndex: currentReader.index,
      eventType: 'word',
      page: currentPageIndex,
      wordIdx: wordIndex,
    })
    dispatch(bookActions.readWord(wordEvent))
    if (isLastWord(wordIndex)) {
      dispatch(bookActions.setPageDone(true))
    } else {
      setPageStep(PAGE_STEP.INITIAL)
    }
  }

  const withBookInfo = (props) => ({
    bookTitle: bookInfo.config.cover.title,
    readingLevel: bookInfo.config.reading_level,
    targets: bookInfo.config.targets,
    teacherID: teacher.id,
    ...props,
  })

  const getEventByType = (eventType) => {
    return events.find((e) => e.eventType === eventType)
  }

  const createSessionData = () => {
    const sessionEnd = new Date()
    const sessionEndStr = sessionEnd.toISOString()
    const sessionStartStr = sessionStart.toISOString()
    return withBookInfo({
      mode,
      version: 0.1,
      sessionDuration: (sessionEnd.getTime() - sessionStart.getTime()) / 1000,
      sessionEndStr,
      sessionStartStr,
      sessionEnd: sessionEndStr,
      sessionStart: sessionStartStr,
      comprehensionCheck: getEventByType('comprehensionCheck'),
      comprehensionCheck2: {
        ...getEventByType('comprehensionCheck2'),
        eventType: 'comprehensionCheck',
      },
      childID: readers[0].ID,
      child2ID: readers[1]?.ID,
      decodingCheck: getEventByType('decodingCheck'),
      decodingCheck2: {
        ...getEventByType('decodingCheck2'),
        eventType: 'decodingCheck',
      },
      metadata: bookInfo.config,
      events: events.map(withBookInfo),
    })
  }

  const submitSessionData = async () => {
    const token = await auth0.getAccessTokenSilently({
      authorizationParams:{
        audience:process.env.REACT_APP_AUTH_AUDIENCE,
        scope: process.env.REACT_APP_AUTH_SCOPE
      }
    })
    return api.post('/api/session/new', createSessionData(), {
      headers: { authorization: `Bearer ${token}` },
    })
  }

  const handleToolbarClick = (scaffoldType, scaffoldData) => {
    dispatch(
      bookActions.toolbox(
        createEvent({
          readerIndex: currentReader.index,
          eventType: scaffoldData ? 'toolbox' : 'toolbox-incorrectstrategy',
          page: currentPageIndex,
          wordIdx: currentWordIndex,
          scaffoldType,
          scaffoldData,
        })
      )
    )
  }

  const challenge = bookInfo.getChallenges(readers.length)[
    currentPageIndex - pageItems.length
  ]

  let isAltReader = false
  if (mode === readingModes.BUDDY_MODE) {
    isAltReader = !(currentPageIndex % readers.length)
  }

  if (challenge) {
    return (
      <div
        className={styles.wrapper}
        style={{
          background: `url(${wallBackgroundImageSrc})`,
          backgroundSize: 'cover',
        }}
      >
        <div
          className={cn(styles.currentReaderAvatar, {
            [styles.badgerAvatar]: readAloudPlaying,
            [styles.avatarAlt]: isAltReader,
          })}
        >
          <img
            src={readAloudPlaying ? badgerImageSrc : currentReader.avatarImgSrc}
            alt=""
          />
        </div>
        {renderChallenge(challenge)}
        <AudioPlayer
          ref={successAudioRef}
          src={successAudioSrc}
          autoPlay={false}
          controls={false}
          volume={0.05}
        />
        <AudioPlayer
          ref={failAudioRef}
          src={failAudioSrc}
          autoPlay={false}
          controls={false}
          volume={0.05}
        />
      </div>
    )
  }

  if (done) {
    return (
      <div className={styles.endPageWrapper}>
        <div className={styles.endPageDialogContainer}>
          <div className={styles.endPageDialogButtonContainer}>
            <HomeButton className={styles.endPageDialogButton} to="/" />            
            <div className={styles.endPageDialogButtonLabel}>Back to Start</div>
          </div>
          <div className={styles.endPageDialogButtonContainer}>
            <KeepReadingButton
              className={styles.endPageDialogButton}
              to="/pick-level"
            />
            <div className={styles.endPageDialogButtonLabel}>Keep Reading</div>
          </div>
        </div>
      </div>
    )
  }

  if (currentPageIndex >= pageItems.length && !loading) {
    setLoading(true)
    submitSessionData().then((d) => {
      setDone(true)
      setLoading(false)
    })
  }

  return (
    // NEW VERSION
    <LoadingOverlay loading={loading}>
      <div style={{height:'100vh', width:'100%', background:'#fff', padding:15}}>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', height:'5vh'}}>
          <div style={{width:'30%'}}>
            <div style={{display:'flex', flexDirection:'row'}}>
              <HomeButton className={styles.homeButton} />
            </div>
          </div>
          <div style={{width:'30%'}}>
              <button className={styles.lastPageButton} onClick={last}>Go to last</button>
          </div>
          <div style={{width:'30%'}}>
          </div>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', paddingBottom:15, height:'72vh'}}>
          <div style={{width:'15%', height:'100%', paddingTop:35}}>
            {isReading() && currentReader.avatarImgSrc && (
              <div
                className={cn(styles.currentReaderAvatar, {
                  [styles.badgerAvatar]: readAloudPlaying,
                  [styles.avatarAlt]: isAltReader,
                })}
              >
                <img
                  src={readAloudPlaying ? badgerImageSrc : currentReader.avatarImgSrc}
                  alt=""
                />
              </div>
            )}
          </div>
          <div style={{width:'70%', height:'100%'}}>
            <div className={styles.readerMainContainer}>
              {isRunningVideo() && (
                <Stage
                  coverImage={bookInfo.requestMediaFile(
                    bookInfo.config.cover.image
                  )}
                  coverAudio={bookInfo.requestMediaFile(
                    bookInfo.config.cover.audio
                  )}
                  preReadingVideo={bookInfo.requestMediaFile(
                    'PreReadingVideo.mp4'
                  )}
                  onEnded={() => setCurrentState(STAGE_STATE.READING)}
                />
              )}
              <Carousel
                  activeIndex={currentPageIndex}
                  next={next}
                  keyboard={false}
                  previous={previous}
                  className={cn({ 'd-none': !isReading() }, styles.carousel)}
                  pause={false}
                  interval={false}
              >
                {pageItems.map(({ id, backgroundImageSrc }) => (
                  <CarouselItem
                    className={cn(styles.carouselItem, 'custom-tag')}
                    tag="div"
                    key={backgroundImageSrc}
                  >
                    <img src={backgroundImageSrc} alt={`page${id}`} />
                    {/* <div className={styles.carouselImageBorder} /> */}
                  </CarouselItem>
                ))}
                {isLastWord() && pageStep === PAGE_STEP.READ_ALOUD && (
                  <div className={styles.checkAudioButtonWrapper}>
                    <AudioPlayerButton
                      className={styles.checkAudioButton}
                      onPlay={() => setReadAloudPlaying(true)}
                      onEnded={() => {
                        setReadAloudPlaying(false)
                        setPageStep(PAGE_STEP.FINISHED)
                      }}
                      src={pageItems[currentPageIndex]?.readAloudAudioSrc}
                    >
                      <img src={checkAudioButton} alt="" />
                    </AudioPlayerButton>
                  </div>
                )}
              </Carousel>
            </div>
          </div>
          <div style={{width:'15%', height:'100%', position:'relative'}}>
            <div style={{cursor:'pointer', position:'absolute', bottom:15, right:0}}>
              {isReading() && (
                <Toolbar
                  className={styles.toolbar}
                  scaffolds={
                    pageItems[currentPageIndex]?.text[currentWordIndex]
                      ?.scaffolds
                  }
                  handleClick={handleToolbarClick}
                  bookInfo={bookInfo}
                  pageIndex={currentPageIndex}
                  wordIndex={currentWordIndex}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', height:'18vh'}}>
          {isReading() && (
            <>
              {currentPageIndex ? (
                <PrevPageButton
                  onClick={previous}
                  className={styles.carouselControlButton}
                />
              ) : null}
              <div className={styles.readerSubTextCenterContainer}>
                <Slider
                  hidden={pageStep === PAGE_STEP.READ_ALOUD}
                  toolboxAction={toolbox}
                  pageItems={pageItems}
                  words={pageItems[currentPageIndex]?.words}
                  bookInfo={bookInfo}
                  onChangeWord={handleWordChange}
                  color={isAltReader && 'secondary'}
                  pageIndex={currentPageIndex}
                  pageStep={pageStep}
                  PAGE_STEP={PAGE_STEP}
                />
              </div>
              <NextPageButton
                onClick={next}
                className={cn(styles.next, styles.carouselControlButton, [
                  {
                    disabled:
                      !pageDone ||
                      pageStep === PAGE_STEP.READ_ALOUD ||
                      !isLastWord(),
                  },
                ])}
              />
            </>
          )}
        </div>
      </div>
    </LoadingOverlay>
    
    // OLD VERSION
    // <LoadingOverlay loading={loading}>
    //   {isReading() && currentReader.avatarImgSrc && (
    //     <div
    //       className={cn(styles.currentReaderAvatar, {
    //         [styles.badgerAvatar]: readAloudPlaying,
    //         [styles.avatarAlt]: isAltReader,
    //       })}
    //     >
    //       <img
    //         src={readAloudPlaying ? badgerImageSrc : currentReader.avatarImgSrc}
    //         alt=""
    //       />
    //     </div>
    //   )}
    //   <div className={cn(styles.wrapper)}>
    //     <div className={styles.readerContainer}>
    //       <div className={styles.readerMainContainer}>
    //         <HomeButton className={styles.homeButton} />
    //         <button className={styles.lastPageButton} onClick={last}>Go to last</button>
    //         {isRunningVideo() && (
    //           <Stage
    //             coverImage={bookInfo.requestMediaFile(
    //               bookInfo.config.cover.image
    //             )}
    //             coverAudio={bookInfo.requestMediaFile(
    //               bookInfo.config.cover.audio
    //             )}
    //             preReadingVideo={bookInfo.requestMediaFile(
    //               'PreReadingVideo.mp4'
    //             )}
    //             onEnded={() => setCurrentState(STAGE_STATE.READING)}
    //           />
    //         )}
    //         <div className={styles.toolbarContainer}>
    //           {isReading() && (
    //             <Toolbar
    //               className={styles.toolbar}
    //               scaffolds={
    //                 pageItems[currentPageIndex]?.text[currentWordIndex]
    //                   ?.scaffolds
    //               }
    //               handleClick={handleToolbarClick}
    //               bookInfo={bookInfo}
    //               pageIndex={currentPageIndex}
    //               wordIndex={currentWordIndex}
    //             />
    //           )}
    //         </div>
    //         <Carousel
    //           activeIndex={currentPageIndex}
    //           next={next}
    //           keyboard={false}
    //           previous={previous}
    //           className={cn({ 'd-none': !isReading() }, styles.carousel)}
    //           pause={false}
    //           interval={false}
    //         >
    //           {pageItems.map(({ id, backgroundImageSrc }) => (
    //             <CarouselItem
    //               className={cn(styles.carouselItem, 'custom-tag')}
    //               tag="div"
    //               key={backgroundImageSrc}
    //             >
    //               <img src={backgroundImageSrc} alt={`page${id}`} />
    //               <div className={styles.carouselImageBorder} />
    //             </CarouselItem>
    //           ))}
    //         </Carousel>
    //       </div>
    //       <div className={styles.readerSubTextContainer}>
    //         {isReading() && (
    //           <>
    //             {currentPageIndex ? (
    //               <PrevPageButton
    //                 onClick={previous}
    //                 className={styles.carouselControlButton}
    //               />
    //             ) : null}
    //             <div className={styles.readerSubTextCenterContainer}>
    //               <Slider
    //                 hidden={pageStep === PAGE_STEP.READ_ALOUD}
    //                 toolboxAction={toolbox}
    //                 pageItems={pageItems}
    //                 words={pageItems[currentPageIndex]?.words}
    //                 bookInfo={bookInfo}
    //                 onChangeWord={handleWordChange}
    //                 color={isAltReader && 'secondary'}
    //                 pageIndex={currentPageIndex}
    //               />
    //             </div>
    //             <NextPageButton
    //               onClick={next}
    //               className={cn(styles.next, styles.carouselControlButton, [
    //                 {
    //                   disabled:
    //                     !pageDone ||
    //                     pageStep === PAGE_STEP.READ_ALOUD ||
    //                     !isLastWord(),
    //                 },
    //               ])}
    //             />
    //           </>
    //         )}
    //       </div>
    //     </div>
    //     {isLastWord() && pageStep === PAGE_STEP.READ_ALOUD && (
    //       <div className={styles.checkAudioButtonWrapper}>
    //         <AudioPlayerButton
    //           className={styles.checkAudioButton}
    //           onPlay={() => setReadAloudPlaying(true)}
    //           onEnded={() => {
    //             setReadAloudPlaying(false)
    //             setPageStep(PAGE_STEP.FINISHED)
    //           }}
    //           src={pageItems[currentPageIndex]?.readAloudAudioSrc}
    //         >
    //           <img src={checkAudioButton} alt="" />
    //         </AudioPlayerButton>
    //       </div>
    //     )}
    //   </div>
    // </LoadingOverlay>
  )
}

export default connect((state) => ({
  ...state.book,
  students: state.classroom.students,
  teacher: state.classroom.teacher,
}))(Reader)
