// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__g1I7p {
  display: flex;
  margin-top: auto;
  height: 90%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px) {
  .styles_container__g1I7p {
    flex-direction: column;
  }
}
.styles_container__g1I7p img {
  height: 100%;
}

.styles_readAloudButton__da\\+q3 {
  width: 100px;
  margin: 20px 60px;
}
@media (max-width: 1280px) {
  .styles_readAloudButton__da\\+q3 {
    margin: 20px 40px;
  }
}
@media (max-width: 600px) {
  .styles_readAloudButton__da\\+q3 {
    width: 80px;
    margin: 20px;
  }
}

.styles_rightContainer__NLw\\+7,
.styles_leftContainer__tYFeT {
  text-align: center;
  width: 32vw;
  height: 32vw;
}
.styles_rightContainer__NLw\\+7 img,
.styles_leftContainer__tYFeT img {
  width: 32vw;
  height: 32vw;
  opacity: 0.9;
  border-radius: 5%;
  cursor: pointer;
}
@media (max-width: 600px) {
  .styles_rightContainer__NLw\\+7 img,
.styles_leftContainer__tYFeT img {
    height: 30vw;
    width: 30vh;
  }
}
.styles_rightContainer__NLw\\+7 img:hover,
.styles_leftContainer__tYFeT img:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Reader/ComprehensionCheckTypeB/styles.module.scss","webpack://./src/styles/totm/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EAIA,mBAAA;EACA,uBAAA;AAJF;ACNI;EDAJ;IAOI,sBAAA;EAGF;AACF;AAAE;EACE,YAAA;AAEJ;;AAEA;EACE,YAAA;EAEA,iBAAA;AAAF;ACXI;EDQJ;IAKI,iBAAA;EAEF;AACF;ACxBI;EDgBJ;IAQI,WAAA;IACA,YAAA;EAIF;AACF;;AADA;;EAEE,kBAAA;EACA,WAAA;EACA,YAAA;AAIF;AAHE;;EAEE,WAAA;EACA,YAAA;EAKA,YAAA;EACA,iBAAA;EACA,eAAA;AACJ;AC7CI;EDkCF;;IAKI,YAAA;IACA,WAAA;EAWJ;AACF;AAPI;;EACE,UAAA;AAUN","sourcesContent":["@import 'styles/totm/_mixins';\n\n.container {\n  display: flex;\n  margin-top: auto;\n  height: 90%;\n  width: 100%;\n  flex-direction: row;\n  @include respond-to(xs) {\n    flex-direction: column;\n  }\n  align-items: center;\n  justify-content: center;\n  img {\n    height: 100%;\n  }\n}\n\n.readAloudButton {\n  width: 100px;\n\n  margin:20px 60px;\n  @include respond-to(lm) {\n    margin:20px 40px;\n  }\n  @include respond-to(xs) {\n    width: 80px;\n    margin:20px;\n  }\n}\n\n.rightContainer,\n.leftContainer {\n  text-align: center;\n  width: 32vw;\n  height: 32vw;\n  img {\n    \n    width: 32vw;\n    height: 32vw;\n    @include respond-to(xs) {\n      height: 30vw;\n      width: 30vh;\n    }\n    opacity: 0.9;\n    border-radius: 5%;\n    cursor: pointer;\n    &:hover {\n      opacity: 1;\n    }\n  }\n}\n","@mixin respond-to($media) {\n  @if $media == xs {\n    @media (max-width: 600px) {\n      @content;\n    }\n  } @else if $media == sm {\n    @media (max-width: 768px) {\n      @content;\n    }\n  } @else if $media == lm {\n    @media (max-width: 1280px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__g1I7p`,
	"readAloudButton": `styles_readAloudButton__da+q3`,
	"rightContainer": `styles_rightContainer__NLw+7`,
	"leftContainer": `styles_leftContainer__tYFeT`
};
export default ___CSS_LOADER_EXPORT___;
