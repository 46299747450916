// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_contentContainer__MbTc9 {
  font-size: 26px !important;
  font-weight: bold !important;
  min-height: 130px !important;
  display: flex;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.517);
  border-radius: 12px !important;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  text-align: center;
  width: 100%;
}
@media (max-width: 600px) {
  .Button_contentContainer__MbTc9 {
    font-size: 18px !important;
    min-height: 100px !important;
    padding: 10px;
  }
}

.Button_contentRight__zdroY,
.Button_contentLeft__foBHv {
  min-width: 100px;
}
.Button_contentRight__zdroY img,
.Button_contentLeft__foBHv img {
  width: 100px;
}
@media (max-width: 600px) {
  .Button_contentRight__zdroY,
.Button_contentLeft__foBHv {
    min-width: 70px;
  }
  .Button_contentRight__zdroY img,
.Button_contentLeft__foBHv img {
    width: 70px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonsCardLayout/Button.module.scss","webpack://./src/styles/totm/_mixins.scss"],"names":[],"mappings":"AAIA;EACE,0BAAA;EACA,4BAAA;EACA,4BAAA;EACA,aAAA;EACA,6CAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,WAAA;AAHF;ACXI;EDEJ;IAcI,0BAAA;IACA,4BAAA;IACA,aAAA;EADF;AACF;;AAIA;;EAEE,gBAxBE;AAuBJ;AAGE;;EACE,YA3BA;AA2BJ;AC3BI;EDsBJ;;IASI,eAAA;EACF;EACE;;IACE,WAAA;EAEJ;AACF","sourcesContent":["@import 'styles/totm/_mixins';\n\n$w: 100px;\n\n.contentContainer {\n  font-size: 26px !important;\n  font-weight:bold !important;\n  min-height: 130px !important;\n  display: flex;\n  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.517);\n  border-radius:12px !important;\n  flex-direction: row;\n  align-items: center;\n  padding: 15px;\n  justify-content: space-between;\n  text-align: center;\n  width: 100%;\n  @include respond-to(xs) {\n    font-size: 18px !important;\n    min-height: 100px !important;\n    padding: 10px;\n  }\n}\n\n.contentRight,\n.contentLeft {\n  min-width: $w;\n\n  img {\n    width: $w;\n  }\n\n  @include respond-to(xs) {\n    min-width: #{$w * 0.7};\n\n    img {\n      width: #{$w * 0.7};\n    }\n  }\n}\n","@mixin respond-to($media) {\n  @if $media == xs {\n    @media (max-width: 600px) {\n      @content;\n    }\n  } @else if $media == sm {\n    @media (max-width: 768px) {\n      @content;\n    }\n  } @else if $media == lm {\n    @media (max-width: 1280px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": `Button_contentContainer__MbTc9`,
	"contentRight": `Button_contentRight__zdroY`,
	"contentLeft": `Button_contentLeft__foBHv`
};
export default ___CSS_LOADER_EXPORT___;
