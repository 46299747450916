// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_skipButton__EP5Hp {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  font-size: 1em;
  font-weight: 600;
  margin-right: 1em;
  color: #51a0f3;
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/components/SkipButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;AACF","sourcesContent":[".skipButton {\n  cursor: pointer;\n  user-select: none;\n  font-size: 1em;\n  font-weight:600;\n  margin-right: 1em;\n  color: #51a0f3;\n  z-index: 999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skipButton": `styles_skipButton__EP5Hp`
};
export default ___CSS_LOADER_EXPORT___;
