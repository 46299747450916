// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_bookCard__bimQG {
  margin: 20px !important;
}
.styles_bookCard__bimQG img {
  width: 220px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/BookSelectionPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAAE;EACE,uBAAA;AAEJ","sourcesContent":[".bookCard {\n  margin: 20px!important;\n  img {\n    width: 220px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookCard": `styles_bookCard__bimQG`
};
export default ___CSS_LOADER_EXPORT___;
