// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_scene__oiNxR {
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

@keyframes styles_animateCardIn__-CTt2 {
  from {
    transform-style: preserve-3d;
    transform: rotateY(90deg) scale(0.7);
  }
  to {
    transform-style: preserve-3d;
    transform: rotateY(0deg) scale(1);
  }
}
@keyframes styles_animateCardOut__4ZDJW {
  from {
    transform-style: preserve-3d;
    transform: rotateY(0deg) scale(1);
  }
  to {
    transform-style: preserve-3d;
    transform: rotateY(90deg) scale(0.7);
  }
}
.styles_cardContainer__UwiwS {
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-name: styles_animateCardIn__-CTt2;
}

.styles_cardContainer__UwiwS.styles_animateOut__sNyB\\+ {
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-name: styles_animateCardOut__4ZDJW;
}

.styles_footerText__nu8GM {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonsCardLayout/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,4BAAA;EACA,0BAAA;AADF;;AAIA;EACE;IACE,4BAAA;IACA,oCAAA;EADF;EAGA;IACE,4BAAA;IACA,iCAAA;EADF;AACF;AAIA;EACE;IACE,4BAAA;IACA,iCAAA;EAFF;EAIA;IACE,4BAAA;IACA,oCAAA;EAFF;AACF;AAKA;EACE,wBAAA;EACA,+BAAA;EACA,2CAAA;AAHF;;AAMA;EACE,wBAAA;EACA,+BAAA;EACA,4CAAA;AAHF;;AAMA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AAHF","sourcesContent":["$w: 100px;\n\n.scene {\n  perspective: 1000px;\n  transform-style: preserve-3d;\n  transition: transform 0.8s;\n}\n\n@keyframes animateCardIn {\n  from {\n    transform-style: preserve-3d;\n    transform: rotateY(90deg) scale(0.7);\n  }\n  to {\n    transform-style: preserve-3d;\n    transform: rotateY(0deg) scale(1);\n  }\n}\n\n@keyframes animateCardOut {\n  from {\n    transform-style: preserve-3d;\n    transform: rotateY(0deg) scale(1);\n  }\n  to {\n    transform-style: preserve-3d;\n    transform: rotateY(90deg) scale(0.7);\n  }\n}\n\n.cardContainer {\n  animation-duration: 0.8s;\n  animation-timing-function: ease;\n  animation-name: animateCardIn;\n}\n\n.cardContainer.animateOut {\n  animation-duration: 0.8s;\n  animation-timing-function: ease;\n  animation-name: animateCardOut;\n}\n\n.footerText {\n  margin-top: 40px;\n  text-align: center;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scene": `styles_scene__oiNxR`,
	"cardContainer": `styles_cardContainer__UwiwS`,
	"animateCardIn": `styles_animateCardIn__-CTt2`,
	"animateOut": `styles_animateOut__sNyB+`,
	"animateCardOut": `styles_animateCardOut__4ZDJW`,
	"footerText": `styles_footerText__nu8GM`
};
export default ___CSS_LOADER_EXPORT___;
