// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__aLsAF {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  font-size: 24px;
  font-weight: 600;
}

.video-react .video-react-video, .video-react .video-react-poster, .video-react.video-react-fluid {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff !important;
  height: calc(100vh - 65px) !important;
}

.styles_readAloudCoverButton__Q\\+ur4 {
  position: absolute;
  width: 80px;
  margin: 20px;
  right: 0;
  z-index: 9999;
  bottom: 0;
}
@media (max-width: 600px) {
  .styles_readAloudCoverButton__Q\\+ur4 {
    width: 40px;
  }
}

.styles_playerOverlay__LKwj\\+ {
  height: "100%";
  width: "100%";
}`, "",{"version":3,"sources":["webpack://./src/components/Reader/Stage/styles.module.scss","webpack://./src/styles/totm/_mixins.scss"],"names":[],"mappings":"AAEA;EAQE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AARF;;AAYE;EAGE,qBAAA;EACA,oBAAA;EACA,iCAAA;EACA,qCAAA;AAXJ;;AAeA;EACE,kBAAA;EACA,WAAA;EAIA,YAAA;EACA,QAAA;EACA,aAAA;EACA,SAAA;AAfF;ACrBI;ED2BJ;IAII,WAAA;EANF;AACF;;AAaA;EAEE,cAAA;EACA,aAAA;AAXF","sourcesContent":["@import 'styles/totm/_mixins';\n\n.header {\n  // display: flex;\n  // align-items: center;\n  // justify-content: flex-end;\n  // height: 65px;\n  // @include respond-to(xs) {\n  //   height: 35px;\n  // }\n  position:absolute;\n  top:0;\n  right:0;\n  padding:15px;\n  font-size: 24px;\n  font-weight: 600;\n}\n\n:global(.video-react) {\n  & :global(.video-react-video),\n  & :global(.video-react-poster),\n  &:global(.video-react-fluid) {\n    padding: 0 !important;\n    margin: 0 !important;\n    background-color: #fff!important;\n    height: calc(100vh - 65px) !important;\n  }\n}\n\n.readAloudCoverButton {\n  position: absolute;\n  width: 80px;\n  @include respond-to(xs) {\n    width: 40px;\n  }\n  margin: 20px;\n  right: 0;\n  z-index: 9999;\n  bottom: 0;\n}\n\n.playerOverlay {\n  // position: fixed;\n  height: '100%';\n  width: '100%';\n  // z-index: 999;\n}\n","@mixin respond-to($media) {\n  @if $media == xs {\n    @media (max-width: 600px) {\n      @content;\n    }\n  } @else if $media == sm {\n    @media (max-width: 768px) {\n      @content;\n    }\n  } @else if $media == lm {\n    @media (max-width: 1280px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__aLsAF`,
	"readAloudCoverButton": `styles_readAloudCoverButton__Q+ur4`,
	"playerOverlay": `styles_playerOverlay__LKwj+`
};
export default ___CSS_LOADER_EXPORT___;
