// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__LXQds {
  cursor: pointer;
}

.styles_button__LXQds:hover,
.styles_button__LXQds:active,
.styles_button__LXQds.styles_focus__mZbGm {
  border-radius: 50%;
}

.styles_button__LXQds:hover {
  background-color: #f2763741;
}

.styles_button__LXQds:active {
  box-shadow: 0 0 0 2px #f2763741;
}

.styles_stopRecording__HkaVf {
  margin-top: 1em;
  color: #e10606 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/AudioRecordButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;;;EAGE,kBAAA;AAEF;;AACA;EACE,2BAAA;AAEF;;AACA;EACE,+BAAA;AAEF;;AACA;EACE,eAAA;EACA,yBAAA;AAEF","sourcesContent":[".button {\n  cursor: pointer;\n}\n.button:hover,\n.button:active,\n.button.focus {\n  border-radius: 50%;\n}\n\n.button:hover {\n  background-color: #f2763741;\n}\n\n.button:active {\n  box-shadow: 0 0 0 2px #f2763741;\n}\n\n.stopRecording {\n  margin-top: 1em;\n  color: #e10606 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__LXQds`,
	"focus": `styles_focus__mZbGm`,
	"stopRecording": `styles_stopRecording__HkaVf`
};
export default ___CSS_LOADER_EXPORT___;
